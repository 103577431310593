<template>
    <div class="salesStatistics">
        <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
        >
            <el-menu-item index="1">
                按销售区域
            </el-menu-item>
            <el-menu-item index="2">
                按合同类型
            </el-menu-item>
            <el-menu-item index="3">
                按业务员
            </el-menu-item>
        </el-menu>
        <div class="salesStatisticsBox" v-if="activeIndex == 1">
            <div class="top-echarts-two">
                <div class="echarts-left echarts-total">
                    <div class="title-box">
                        <h1>销售方量</h1>
                        <span @click="lookMore(7)">查看完整数据</span>
                    </div>
                    <div style="width 100%;height:2.7rem;" id="contractQuantityId"></div>
                </div>
                <div class="echarts-right echarts-total">
                    <div class="title-box">
                        <h1>销售金额</h1>
                        <span @click="lookMore(8)">查看完整数据</span>
                    </div>
                    <div style="width 100%;height:2.7rem;" id="estimatedSalesId"></div>
                </div>
            </div>
            <exportDom :dom-id="'.el-table__fixed-right'" :excel-name="'按销售区域近1年销售情况'"></exportDom>
            <div class="table-list">
                <el-table
                    :data="tabListDataName"
                    style="width: 100%"
                    max-height="100%"
                    ref="tableRef"
                    show-summary
                    :summary-method="getSummaries"
                >
                    <el-table-column
                        prop="name"
                        label="公司名称"
                        width="150"
                        fixed
                    >
                    </el-table-column>
                    <el-table-column
                        v-for="(date, index) in tabListDataDate"
                        :key="index"
                        :label="date"
                    >
                        <el-table-column
                            :prop="date"
                            width="120"
                            label="销售方量(方)"
                        >
                            <template #header>
                                销售方量<br>(方)
                            </template>
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.xsfl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            :prop="date"
                            width="120"
                            label="销售金额(元)"
                        >
                            <template #header>
                                销售金额<br>(元)
                            </template>
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.xsje }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        fixed="right"
                        width="150"
                        label="销售方量小计(方)"
                    >
                        <template #header>
                            销售方量小计<br>(方)
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="htTotal"
                        fixed="right"
                        width="150"
                        label="销售金额小计(元)"
                    >
                        <template #header>
                            销售金额小计<br>(元)
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <typeOfContract v-if="activeIndex === '2'"></typeOfContract>
        <salesman v-if="activeIndex === '3'"></salesman>
        <alertEcharts v-if="showContract" :alert-status="alertStatus" @closeChildAlert="closeChildFn"></alertEcharts>
        <yearDataAnalysis
            v-if="showYearData"
            :click-name="clickName"
            :click-echarts-id="clickEchartsId"
            @closeYearAlert="closeYearAlert"
        ></yearDataAnalysis>
    </div>
</template>

<script>
import exportDom from './components/export';
import { heckAllCnText } from './utils';
// 查看更多
import alertEcharts from './components/contractQuantit';
// 按销售区域弹窗
import yearDataAnalysis from './components/yearDataAnalysis/yearDataAnalysis';
// 按合同类型tab
import typeOfContract from './components/yearDataAnalysis/typeOfContract';
// 按业务员tab
import salesman from './components/yearDataAnalysis/salesman';

export default {
    data() {
        return {
            activeIndex: '1',

            tabListEcharts: [],
            showContract: false,
            showYearData: false,
            alertStatus: 0,
            tabListData: [],
            tabListDataName: [],
            tabListDataList: [],
            tabListDataDate: [],
            tabListEchartsHt: [],
            clickName: '',
            clickEchartsId: '',
            echartsColor: ['#EA8343', '#3E79C4', '#E6E429', '#E44F4F'],
        };
    },
    components: {
        alertEcharts,
        yearDataAnalysis,
        typeOfContract,
        salesman,
        exportDom,
    },
    mounted() {
        this.$nextTick(() => {
            // 近一年销售情况
            setTimeout(() => {
                this.salescontractMonths();
                this.$refs.tableRef.doLayout();
            }, 500);
        });
    },
    methods: {
        handleSelect(key) {
            this.activeIndex = key;
            if (key === '1') {
                this.salescontractMonths();
            }
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                // 获取与当前行时间相同的数据
                const arr = this.tabListDataList.filter(item => {
                    return column.property === item.date;
                });
                // 对应时间合同数量和预计销售方量(方)的数值数组 // 对应每一列的数据数组
                // eslint-disable-next-line consistent-return
                let values = arr.map(item => {
                    if (column.label === '销售方量(方)') {return item.xsfl;}
                    if (column.label === '销售金额(元)') {return item.xsje;}
                });

                if (column.label === '合计销售金额(元)') {
                    // eslint-disable-next-line consistent-return
                    values = data.map(item => {
                        if (column.label === '合计销售金额(元)') {
                            return item.htTotal;
                        }
                    });
                }
                if (column.label === '合计销售方量(方)') {
                    // eslint-disable-next-line consistent-return
                    values = data.map(item => {
                        if (column.label === '合计销售方量(方)') {
                            return item.total;
                        }
                    });
                }
                // const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return Math.round(prev + curr * 100);
                        }
                        return prev;
                    }, 0);
                    sums[index] = sums[index] / 100;
                } else {
                    sums[index] = ' ';
                }
            });
            return sums;
        },
        closeChildFn() {
            this.showContract = false;
        },
        closeYearAlert() {
            this.showYearData = false;
        },
        // 查看详情
        lookMore(status) {
            // status 7:销售方量  8:销售金额
            this.alertStatus = status;
            this.showContract = true;
        },
        // 销售方量
        contractQuantityCh(data) {
            const myChart = this.$echarts.init(document.getElementById('contractQuantityId'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    padding: [10, 0, 0, 0],
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListEcharts,
                        // triggerEvent: true,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function (params) {
                if (params.componentType === 'xAxis') {
                    that.clickName = params.value;
                    that.showYearData = true;
                } else {
                    that.clickName = params.name;
                    that.tabListDataList.forEach(item => {
                        if (item.name === params.seriesName && item.date === params.name) {
                            that.clickEchartsId = item.id;
                        }
                    });
                    that.showYearData = true;
                }
            });
            myChart.setOption(option, true);
        },
        // 销售金额
        estimatedSalesCh(data) {
            const myChart = this.$echarts.init(document.getElementById('estimatedSalesId'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    padding: [10, 0, 0, 0],
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListEcharts,
                        // triggerEvent: true,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(元)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(元)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function (params) {
                if (params.componentType === 'xAxis') {
                    that.clickName = params.value;
                    that.showYearData = true;
                } else {
                    that.clickName = params.name;
                    that.tabListDataList.forEach(item => {
                        if (item.name === params.seriesName && item.date === params.name) {
                            that.clickEchartsId = item.id;
                        }
                    });
                    that.showYearData = true;
                }
            });
            myChart.setOption(option, true);
        },

        // 近一年销售情况
        async salescontractMonths() {
            this.tabListEcharts = [];
            const month = new Date().getMonth() + 1;
            await this.$axios
                .get('/interfaceApi/report/salesanalysis/xsqk/xsqy/months?date=&datenum=' + month)
                .then(res => {
                    this.tabListData = res;
                    this.tabListDataList = res.list;
                    this.tabListDataDate = res.date;
                    res.date.forEach(item => {
                        const gangName = heckAllCnText(item);
                        this.tabListEcharts.push(gangName);
                    });
                    // 表格渲染
                    this.tabListDataName = res.name.map(item => {
                        let yjxsflTotal = 0;
                        let htslTotal = 0;
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        arr.forEach(item => {
                            yjxsflTotal += Math.round(item.xsfl * 100) / 100;
                            htslTotal += Math.round(item.xsje * 100) / 100;
                        });
                        return {
                            name: item,
                            list: arr,
                            total: yjxsflTotal,
                            htTotal: htslTotal.toFixed(2),
                        };
                    });
                    // 处理图表数据
                    this.tabListEchartsHt = res.name;
                    this.tabListEchartsHt.push('合计');
                    // 分组名称
                    const getSeriesData = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return arr;
                    });
                    // 销售方量
                    let htEchartSeriesData = [];
                    htEchartSeriesData = getSeriesData.map((item, index) => {
                        let htItemListData = [];
                        htItemListData = item.map(info => {
                            return info.xsfl ? info.xsfl : ',';
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const htEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: htItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return htEchartSeries;
                    });
                    // 销售金额
                    let flEchartSeriesData = [];
                    flEchartSeriesData = getSeriesData.map((item, index) => {
                        let flItemListData = [];
                        flItemListData = item.map(info => {
                            return info.xsje ? info.xsje : ',';
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const flEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: flItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return flEchartSeries;
                    });
                    // 销售方量合计
                    const htTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.xsfl * 100) / 100;
                        });
                        return total;
                    });
                    const htTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: htTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    // 销售金额合计
                    const flTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.xsje * 100) / 100;
                        });
                        return total;
                    });
                    const flTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: flTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    const htCompleteData = htEchartSeriesData.slice(0, -1);
                    htCompleteData.push(htTotal);
                    const flCompleteData = flEchartSeriesData.slice(0, -1);
                    flCompleteData.push(flTotal);
                    // 销售方量图表
                    this.contractQuantityCh(htCompleteData);
                    // 销售金额图表
                    this.estimatedSalesCh(flCompleteData);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.salesStatistics
    height 100%
    display flex
    flex-direction column
    .el-menu
        border-bottom none
        padding .2rem
        padding-top .1rem
        padding-bottom 0
        .el-menu-item
            padding 0 .01rem
            height .4rem
            line-height .4rem
            margin-right .2rem
            color #333333
            font-size .16rem
        .is-active
            color #D6000F
            border-color #D6000F
.salesStatisticsBox
    height 95%
    display flex
    padding .2rem
    display flex
    flex-direction column
    background #fff
    .el-table__fixed-right thead tr th
        &:last-child
            background #F5F2ED !important
            color #823802 !important
        &:nth-last-child(2)
            background #F5F2ED !important
            color #823802 !important
         &:nth-last-child(3)
            background #F5F2ED !important
            color #823802 !important
    .el-table__fixed-body-wrapper tbody tr td
        &:last-child
            color #C9661E !important
        &:nth-last-child(2)
            color #C9661E !important
        &:nth-last-child(3)
            color #C9661E !important
    .el-table__fixed-footer-wrapper table tbody tr
        td
            background none
            color #C9661E
            &:first-child
                background #F5F2ED
                color #823802
                font-size .16rem
    .el-table__footer-wrapper table tbody td
        background none
        color #C9661E
    .top-echarts-two
        // flex 1
        width 100%
        height 3.37rem
        .echarts-total
            float left
            width calc(50% - .1rem)
            // width 100%
            // height 50%
            padding .2rem
            border 1px solid #D7D7D7
            display flex
            flex-direction column
            .title-box
                display flex
                justify-content space-between
                h1
                    color #333333
                    font-size .18rem
                    &:before
                        content '`'
                        width .06rem
                        height .2rem
                        background #4D7DE0
                        color #4D7DE0
                        margin-right .1rem
                span
                    color #1577D2
                    height .25rem
                    padding 0 .2rem;
                    font-size .14rem
                    cursor pointer
                    border 1px solid #1577D2
        .echarts-left
            margin-right .2rem
    .table-list
        margin-top .1rem
        width 100%
        padding-bottom .2rem
        height calc(100% - 3.57rem)
        .el-table
            // overflow: auto;
            // &:after
            //     position:relative !important;
            // .el-table__header
            //     border-collapse: collapse;
            // .el-table__body-wrapper,
            // .el-table__header-wrapper,
            // .el-table__footer-wrapper
            //     overflow: visible;
            thead tr th
                color #022782
                font-size .16rem
                text-align center
                padding 0
            tbody tr td
                height .4rem
                text-align center
                padding 0
                font-size .14rem
    .export
        margin-top .1rem
        height .35rem
        .export_btn
            float right
            border: none;
            height: 0.36rem;
            font-size: 0.14rem;
            min-width: 0.36rem;
            line-height: 0.36rem;
            cursor: pointer;
            padding: 0 0.2rem;
            border-radius: 2px;
            background: #f1f4f7;
            color: #2978ff;
            text-align: center;
</style>
